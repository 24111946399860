<template>
  <div class="store">
    <img src="../../assets/product/store/1.png" class="headerimg" />
    <div class="storeimg">
      <img src="../../assets/product/store/2.png" />
    </div>

    <div class="contertop">
      <div class="title">新电商玩法来袭，你抓住红利了吗？</div>
      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/store/3.png" class="cardimg" />
          <div class="cardtext">直播电商</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/4.png" class="cardimg" />
          <div class="cardtext">短视频导购</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/5.png" class="cardimg" />
          <div class="cardtext">拼团砍价</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/6.png" class="cardimg" />
          <div class="cardtext">会员储值</div>
        </div>
      </div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/store/7.png" class="cardimg" />
          <div class="cardtext">多商户入驻</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/8.png" class="cardimg" />
          <div class="cardtext">分销商城</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/9.png" class="cardimg" />
          <div class="cardtext">新零售</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/10.png" class="cardimg" />
          <div class="cardtext">更多精彩</div>
        </div>
      </div>
    </div>

    <div class="contertop">
      <div class="title">邦伲德商城助力企业抓住风口 轻松搭建3端商城</div>
      <div class="title2">1站3端轻松管理流量铺货</div>

      <div class="advantagelist">
        <div class="advantageimg">
          <img src="../../assets/product/store/11.png" alt="" />
        </div>
        <div class="advantagecard">
          <div class="advantageline">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">小程序商城</div>
              <div class="cardtext">
                覆盖11亿微信用户的小程序商城，入口丰富，传播力强
              </div>
            </div>
          </div>

          <div class="advantageline topandbottom">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">手机微商城</div>
              <div class="cardtext">
                覆盖手机和平板的手机微商城，支持微信，百度，头条等app上访问
              </div>
            </div>
          </div>

          <div class="advantageline">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">电脑商城</div>
              <div class="cardtext">
                覆盖电脑桌面端的商城系统，适配各大浏览器，掌握pc全渠道流量，实现全网营销
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contertop industry">
      <div class="title">1000+行业商城模板0元免费用</div>
      <div class="title2">小程序模版</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/store/12.png" class="cardimg" />
          <div class="cardtext">洗衣店小程序商城模版</div>
        </div>
        <div class="BuildStationcard">
          <img src="../../assets/product/store/13.png" class="cardimg" />
          <div class="cardtext">密室逃脱小程序商城模版</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/14.png" class="cardimg" />
          <div class="cardtext">保健醋小程序商城模版</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/15.png" class="cardimg" />
          <div class="cardtext">JK服饰小程序商城模版</div>
        </div>
      </div>
    </div>

    <div class="contertop case">
      <div class="title">150万+商家的信赖之选</div>
      <div class="title2">案例仅供参考</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/store/16.png" class="cardimg" />
        </div>
        <div class="BuildStationcard">
          <img src="../../assets/product/store/17.png" class="cardimg" />
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/store/18.png" class="cardimg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.case {
  margin-bottom: 125px;
  .BuildStationlist {
    margin-top: 50px;
    width: 50%;
    .BuildStationcard {
      .cardimg {
        width: 256px;
      }
    }
  }
}

.industry {
  .BuildStationlist {
    margin-top: 50px;
    .BuildStationcard {
      .cardimg {
        width: 218px;
      }
      .cardtext {
        margin-top: 21px;
      }
    }
  }
}

.BuildStationlist {
  margin: auto;
  margin-top: 60px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  .BuildStationcard {
    text-align: center;
    .cardimg {
      width: 120px;
    }
    .cardtext {
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
  }
}

.advantagelist {
  margin-top: 90px !important;
  .advantageimg {
    img {
      width: 657px;
    }
  }
  .cardimg {
    width: 25px;
    height: 25px;
    background: #f4d094;
    border-radius: 13px;
    vertical-align: top !important;
  }
}

.storeimg {
  text-align: center;
  img {
    width: 1404px;
    vertical-align: top;
  }
}
</style>